:root {
  --green-500: #5fc7c5;
  --green-600: #309a99;
  --green-100: #c8e2e2;

  --primary-500: #645cff;
  --header-5: #540d83;
}
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

.appCont {
  max-width: 1024px;
  min-height: 100vh;
  width: 100%;
  /* display: inline-block; */
  height: 100%;
  /* border: 4px gray solid; */

  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
}

.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 50;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--green-600);
  box-shadow: 5px 5px 10px #1e2828b8;
}
.header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--header-5);
  z-index: 2;
}
.header__body {
  position: relative;
  /* z-index: 2; */
  display: flex;
  justify-content: space-between;
  height: 100px;
  align-items: center;
}
.header__logo {
  flex: 0 0 300px;
  position: relative;
  z-index: 3;
}
.header__logo img {
  max-width: 100%;
  display: block;
}
.header__burger {
  display: none;
}
.header__list {
  display: flex;
  position: relative;
  z-index: 2;
}
.header__list li {
  list-style: none;
  margin-left: 10px;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 10px;
}

.logoDiv {
  width: 10rem;
  padding: 5px;
  height: 100%;

  margin: 0 0.5rem 0 0.5rem;
}
.logo {
  width: 100%;
}


.navLinks {
  display: none;
}

.section {
  flex: 1 1 auto;
  padding-top: 6rem;
}

.section p{
  text-align: justify;
  padding: 0.5rem;
}
.section h2{
  text-align: center;
}

.article{
  padding: 1rem;
/* border-bottom: #645cff 2px solid; */
border-radius: 0.5rem;
box-shadow: 5px 5px 10px #1e2828b8;
margin: 1rem 0.5rem;
}
.imgCont{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.articlImg{
  flex-grow: 1;
  flex-shrink: 1;
  display: block;
  min-width: 200px;
  max-width: 400px;
  margin: 0.5rem auto;
}
.article li{
  list-style: none;
  margin: 1rem auto;
}
.spanBolt{
  font-weight: bold;
}
.sliderCont {
  width: 60%;
  height: 35rem;
  margin-bottom: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  /* // border: red 2px solid; */
}


.footer {
  /* border: 1px goldenrod solid; */
  background-color: var(--header-5);
  color: var(--green-100);
  text-align: center;
}

.link,
.active {
  margin: 0 0.25rem;
}
.link {
  color: var(--green-100);
}

.active {
  color: var(--primary-500);
}


@media (max-width: 700px) {
  .header__logo {
    flex: 0 0 200px;
    
  }
}
@media (max-width: 600px) {
  .header__logo {
    flex: 0 0 150px;
    
  }
}

@media (max-width: 500px) {
  .header__body {
    
    height: 50px;
    
  }
  .header__logo {
    flex: 0 0 150px;
  }
  .header__burger {
    display: block;
    position: relative;
    width: 30px;
    height: 20px;
    position: relative;
    z-index: 3;
  }
  .header__burger::after,
  .header__burger::before {
    content: "";
    background: white;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    transition: all 0.3s ease 0s;
  }
  .header__burger::before {
    top: 0;
  }
  .header__burger::after {
    bottom: 0;
  }
  .header__burger span {
    position: absolute;
    width: 100%;
    height: 2px;
    background: white;
    top: 49%;
    transition: all 0.3s ease 0s;
  }
  .header__burgerOff span{
   
    transform: scale(0);
    
  }
  .header__burgerOff::after{
    transform: rotate(-45deg);
    bottom: 49%;
  }
  .header__burgerOff::before{
    transform: rotate(45deg);
    top: 42%;
  }

  .header__menu {
    overflow: auto;
    position: fixed;
    top: 0;
    left: 5%;
    width: 90%;
    height: 60%;
    background-color: var(--green-500);
    /* background: linear-gradient(180deg, var(--green-500) 90%, #f69c3c00); */
    padding-top: 80px;
    transition: all 0.3s ease 0s; 
    opacity: 100%;
    box-shadow: 5px 10px 15px #1e2828b8, 
                                0 0 5px var(--green-500);
    /* box-shadow: 5px 10px 15px #1e2828b8, 
                inset 0px 0px 3px  #1e2828b8, 
                0 0 5px var(--green-500); */
    border-radius: 0 0 0.5rem 0.5rem;
    /* box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.80); */
    
  }
  .header__list {
    display: block;
  }
  .header__list li {
    margin-top: 20px;
  }

  .header__menuOff {
    top: -100%;
    opacity: 0;
    border-bottom-right-radius: 100px 250px;
       
  }

  .section {
        padding-top: 3rem;
  }
  .sliderCont {
    width: 100%;
    height: 30rem;
    
  }
}


/* 
@media screen and (min-width: 650px) {
  .navLinks {
    width: 50%;
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    justify-content: space-between;
  }
  .logoDiv {
    width: 15rem;
  }
  .burger {
    display: none;
  }
  .article {
     padding-top: 4rem;
   }
}
@media screen and (min-width: 800px) {
  .logoDiv {
    width: 25rem;
  }
  .article {
    padding-top: 6rem;
  }
} */
