/*--------- Slider -----------------*/
.slider {
  //   border: 2px solid green;
  //   margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  display: flex;
  overflow: hidden;
}
.slider::after {
  height: 100%;
}
.slider__article {
  position: absolute;
  width: 98%;
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /* border: yellow 2px solid; */
  opacity: 0%;
  transition: all 1.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  overflow: hidden;
  h3 {
    padding-bottom: 0.25rem;
  }
  p{
    font-size: 0.85rem;
  }
}
.slider__Img {
  max-height: 80%;
  width: 100%;
  object-fit: contain;
}
.slider__buttonL,
.slider__buttonR {
  position: absolute;
  top: 40%;
  height: 3rem;
  width: 3rem;
  background-color: inherit;
  border: none;
}
.slider__buttonL {
  left: 0;
}
.slider__buttonR {
  right: 0;
}
.slider__arrow {
  width: 100%;
  height: 100%;
  color: azure;
}

.slider__activeSlide {
  transform: translateX(0);
  opacity: 1;
}
.slider__lastSlide {
  transform: translateX(-100%);
}
.slider__nextSlide {
  transform: translateX(100%);
}

.slider__underlineConteiner {
  /* border: solid 2px red; */
  position: absolute;
  bottom: 0.25rem;
  display: flex;
  width: 100%;
  justify-content: center;
}
.slider__underline {
  width: 3rem;
  height: 10px;
  background: #5c596d;
  margin: 0.5rem;
  border-radius: 4px;
  border: none;
  /* border-color: #5c596d; */
}
.slider__underline_active {
  background: #dad5f3;
  box-shadow: -1px 1px 8px #f6f6f8;
}
/*---------=================-----------------*/
